import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    background-color: white;
    color: #0ed2de;
    font-weight: bold;
    font-size: 1rem;
    width: fit-content;
    padding-block: 12px;
    padding-inline: 48px;
    border-radius: 8px;
    margin: auto;
    border: 2.5px solid #0ed2de;
    cursor: pointer;
`;



const SecondaryButton = (prop: {text: string, urlToNavigate: string}) => {
    const navigateTo = (url: string) => {
         global.window && (global.window.location.href = url);
        return null;
    }
    return <StyledButton onClick={() => navigateTo(prop.urlToNavigate)}>{prop.text}</StyledButton>
}


export default SecondaryButton;