import React, { Dispatch, FC, PropsWithChildren } from "react";
import { FIRSTSECTIONSTATE, 
         FOURSECTIONSTATE, 
         IFirstSectionState, 
         IFourSectionState, 
         ISecondSectionState, 
         IThirdSectionState,  
         PartialAppTextState, 
         SECONDSECTIONSTATE, 
         THIRDSECTIONSTATE } from "../data/app-texts";



interface GlobalAppState {
    mainTabSelection: number,
    dispatch: Dispatch<number>
}

interface GlobalTextState {
    language: string,
    firstSectionState: IFirstSectionState,
    secondSectionState: ISecondSectionState,
    thirdSectionState: IThirdSectionState,
    fourSectionState: IFourSectionState,
    dispatch: Dispatch<PartialAppTextState>
}

const globalTextState: GlobalTextState = {
    language: "es",
    firstSectionState: FIRSTSECTIONSTATE,
    secondSectionState: SECONDSECTIONSTATE,
    thirdSectionState: THIRDSECTIONSTATE,
    fourSectionState: FOURSECTIONSTATE,
    dispatch: () => {},
}

const globalState: GlobalAppState = {
    mainTabSelection: 0,
    dispatch: () => {}
  }


//Test State reducer for language selection.
const textReducer = ( state: GlobalTextState, newEntry: PartialAppTextState )  => { 
    //testing purpouse
    const entry = JSON.stringify(newEntry)
    console.log("ESTADO: " + entry.toString())
    const check = "firstSectionState" in newEntry
    console.log(`Esta in ${check}`)

    let newState = state;  

    if( "firstSection" in newEntry ) {
        return {
                language: state.language,
                firstSectionState: newEntry,
                secondSectionState: state.secondSectionState,
                thirdSectionState: state.thirdSectionState,
                fourSectionState: state.fourSectionState,
                dispatch: state.dispatch
        }
    }

    if( "secondSection" in newEntry ) {
        return {
                language: state.language,
                firstSectionState: state.firstSectionState,
                secondSectionState: newEntry,
                thirdSectionState: state.thirdSectionState,
                fourSectionState: state.fourSectionState,
                dispatch: state.dispatch
        }
    }

    if( "thirdSection" in newEntry ) {
        return {
            language: state.language,
            firstSectionState: state.firstSectionState,
            secondSectionState: state.secondSectionState,
            thirdSectionState: newEntry,
            fourSectionState: state.fourSectionState,
            dispatch: state.dispatch
        }
    }

    if( "fourSection" in newEntry ) {
        return {
            language: state.language,
            firstSectionState: state.firstSectionState,
            secondSectionState: state.secondSectionState,
            thirdSectionState: state.thirdSectionState,
            fourSectionState: newEntry,
            dispatch: state.dispatch
        }
    }

    if( "selectedLanguage" in newEntry ) {
        const language = newEntry.selectedLanguage;
        if ( language == "es" ) 
        return {
                language,
                firstSectionState: FIRSTSECTIONSTATE,
                secondSectionState: SECONDSECTIONSTATE,
                thirdSectionState: THIRDSECTIONSTATE,
                fourSectionState: state.fourSectionState,
                dispatch: state.dispatch
            }
            else return {
                    language,
                    firstSectionState: state.firstSectionState,
                    secondSectionState: state.secondSectionState,
                    thirdSectionState: state.thirdSectionState,
                    fourSectionState: state.fourSectionState,
                    dispatch: state.dispatch
            }
    } 
    console.log(`El new State: ${ JSON.stringify(newState)}`)
    return newState;
}


  const reducer = ( state: GlobalAppState, newValue: number) => { return {
        mainTabSelection: newValue,
        dispatch: state.dispatch
    }
        }
        
export const globalStateContext = React.createContext(globalState); 
export const GlobalTextStateContext = React.createContext(globalTextState); 
  

export const GlobalTextStateProvider: FC<PropsWithChildren<{}>> = ({children}) => {
                                    const [state, dispatcher] = React.useReducer(textReducer , globalTextState)
                                    return <GlobalTextStateContext.Provider 
                                              value={
                                                {
                                                     language: state.language, 
                                                     dispatch: dispatcher,
                                                     firstSectionState: state.firstSectionState,
                                                     secondSectionState: state.secondSectionState,
                                                     thirdSectionState: state.thirdSectionState,
                                                     fourSectionState: state.fourSectionState
                                                }
                                                }>
                                         {children}    
                                   </GlobalTextStateContext.Provider>
                                   };

                                   const GlobalStateProvider: FC<PropsWithChildren<{}>> = ({children}) => {
                                    const [state, dispatcher] = React.useReducer(reducer, globalState)
                                    return <globalStateContext.Provider 
                                          value={ 
                                            {   
                                                mainTabSelection: state.mainTabSelection, 
                                                dispatch: dispatcher,
                                            }
                                          }>
                                         {children}    
                                   </globalStateContext.Provider>
                                   };


  export  default GlobalStateProvider;