import React, { useEffect } from 'react';
import styled from 'styled-components';
import ServiceItem from '../services-item/ServiceItem';
import MainButton from '../main-button/MainButton';
import { useTranslation } from 'react-i18next';
import ReactTypingEffect from 'react-typing-effect';
import "./MainSectionText.css";
import { GlobalTextStateContext } from '../../state/GlobalState';

interface TitleProps {
    readonly isPrimary: boolean;
  }

const StyledTitleNormal = styled.h1<TitleProps>`
    font-size: 2rem;
    color: ${props => props.isPrimary? "#000000": "#0ed2de"};
    margin-block: 0;
    font-family: 'Montserrat', sans-serif;
    text-align: center;

    @media (min-width: 820px) {
        text-align: start;
    }

    @media  only screen and (min-width: 769px) and (max-width: 1024px)  {
        font-size: 2rem;
    }

    @media  only screen and (min-width: 756px) and (max-width: 992px)  {
        font-size: 2rem;
    }

    @media  only screen and (min-width: 1025px) {
        font-size:  2.0rem;
    }
`

const StyledText = styled.h5`
    font-size: 1.2rem;
    color: black;
    text-align: start;
    font-family: "Karla";
    font-weight: 400;
    text-align: center;

    @media (min-width: 756px) {
        text-align: start;
    }

    @media only screen and (min-width: 1024px) {
        width: 520px;
    }

    @media  only screen and (min-width: 756px) and (max-width: 992px)  {
        font-size: 1.125rem;
    }
`

const StyledDiv = styled.div`
    height: fit-content; 
    justify-content: center;
    display: flex;
    flex: 50%;
    flex-direction: column;
    margin: 0;
    
    @media  only screen and (min-width: 756px) and (max-width: 992px)  {
        margin-inline-end: 24px;
    }

`

const MainSectionText = () => {
                        const { firstSectionState, dispatch } = React.useContext(GlobalTextStateContext)

                        const services = [firstSectionState.appBranch,
                                          firstSectionState.softwareProfessionals,
                                          firstSectionState.equippedCabins ]
                        return  <StyledDiv>
                                <StyledTitleNormal isPrimary={true}>{firstSectionState.creatService}</StyledTitleNormal>
                                <ReactTypingEffect
                                    className="writer_type"
                                    typingDelay={200}
                                    eraseSpeed={100}
                                    speed={200}
                                    text={ 
                                        [
                                            firstSectionState.telemedicine, firstSectionState.teleMonitoring, firstSectionState.preventiveCheckup, firstSectionState.screening, firstSectionState.automatedTriage]}
                                    displayTextRenderer={(text, i) => {
                                    return <StyledTitleNormal isPrimary={false}>{text}</StyledTitleNormal>
                                    }}        
                                />
                                <StyledText>{firstSectionState.firstSectionSubtitle}</StyledText>
                                {
                                    services.map( (text, index) => <ServiceItem key={index} text={text} /> )
                                }
                                <MainButton/>
                              </StyledDiv>}


export default MainSectionText;