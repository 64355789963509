import React from 'react';
import styled from 'styled-components';
import headerImg from '../../assets/imgs/telemedicina_celular_tablet.png'

const SImgDiv = styled.img`
    height: auto;
    width: 100%;
    flex-grow: 3;
    min-width: 220px;
    max-width: 520px;
    @media (min-width: 756px) {
        
    }
   
`

const StyledImgDiv = () => <SImgDiv src={headerImg}></SImgDiv>;

export default StyledImgDiv;