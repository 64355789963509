import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LogoImg from '../logo/DoctorhelpLogo';
import { useNavigate } from 'react-router-dom';

import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import { globalStateContext } from '../../state/GlobalState';
import logo from '../../assets/imgs/eClinic_logo.png';


interface BottomFooterTextProps {
   readonly clickHandler?: () => void
}

const SFooterContainerDiv = styled.div`
    width: 95%;
    background-color: #f4f5f1;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding-block: 24px;

    @media (min-width: 600px) {
      width: 85%;
      padding-block: 32px;
      margin-block-end: 16px;
      padding-inline: 7%;
    }
`;

const SColumn1Div = styled.div`
    min-width: 300px;
    max-width: 320px;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
`;

const SColumn2Div = styled.div`
    width: 100%;
    justify-content: start;
    display: flex;
    flex-direction: column;


    @media (min-width: 600px) {
      width: fit-content;
      margin-inline-end: 2vw;
    }
    @media (min-width: 756px) {
      width: fit-content;
      margin-inline-end: 4vw;
    }
    @media (min-width: 820px) {
      margin-inline-end: 4vw;
    }
  
    @media (min-width: 960px) {
      margin-inline-end: 6vw;
    }
  
    @media (min-width: 1024px) {
      margin-inline-end: 8vw;
    }
`;

const SColumn3Div = styled.div`
    width: 100%;
    justify-content: start;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
      width: fit-content;
      margin-inline-end: 0vw;
    }

    @media (min-width: 756px) {
      margin-inline-end: 2vw;
    }
    @media (min-width: 820px) {
      margin-inline-end: 3vw;
    }
`;

const SBottomFooterContainer = styled.div`
    width: 100%;
    background-color: #f4f5f1;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding-block-start: 16px;

    @media (min-width: 600px) {
        flex-direction: row;
  }
`;

const STopFooterContainer = styled.div`
    width: 100%;
    background-color: #f4f5f1;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
  }
`;

const SBottomFooterContent = styled.div`
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
    }

    @media (min-width: 756px) {
      justify-content: start;
      flex: 33%;
  }
`;


const SBottomFooterPolicyLinkContent = styled.div`
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
    }

    @media (min-width: 756px) {
      justify-content: center;
      flex: 33%;
  }
`;

const SBottomFooterText = styled.p<BottomFooterTextProps>`
    color: gray;
    font-family: Karla;
    font-size: .9rem;

    &:hover {
      cursor: ${(props) => (props.onClick != null )? "pointer" : null }  
  } 
`;

const SColumnTitle = styled.h1`
  font-size: .9rem;
  text-align: center;
  color: black;
  text-transform: uppercase;

  @media (min-width: 600px) {
    text-align: start;
  }
`;

const StyledListContainer = styled.ul`
  padding-inline: 0;
`;

const SNormalText = styled.a`
  font-size: 1rem;
  text-align: center;
  color: gray; 
  margin-block: 4px;
  text-decoration: none;
  display: block;

  @media (min-width: 600px) {
    text-align: start;
  }

  &:hover {
    cursor: pointer;
  ]

`;

const SContactContainer = styled.div`
  border: 1px solid gray;
  border-radius: 16px;
  width: fit-content;
  margin-block: 24px;
`;

const SContactContainerWrapper = styled.div`
  width: fit-content;
  margin: auto;

  @media (min-width: 600px) {
    margin-inline-start: 5vw;
  }
  
`;

const SContactTitleContainer = styled.h3`
    color: gray;
    font-size: .9rem;
    font-family: Karla;
    text-align: left;
    margin-inline: 1rem;
`;

const SContactTextContainer = styled.h3`
    color: black;
    font-size: .9rem;
    font-family: Karla;
    text-align: left;
    margin-inline: 1rem;
    font-weight: bold;
`;

const SImg = styled.img`
  object-fit: cover;
  height: 200px;
  background-color: #f4f5f1;
`;

const SIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f4f5f1;
  justify-content: center;

  @media (min-width: 600px) {
    align-items: center;
    margin-inline-start: 4.5vw;
  }

`;

const SBottomLeftContainer = styled.div`

  display: flex;
  flex-direction: row;
  background-color: #f4f5f1;
  justify-content: center;

  @media (min-width: 756px) {
    flex: 33%;
  }

`;

const SBottomRigthContainer = styled.div`

  display: flex;
  flex-direction: row;
  background-color: #f4f5f1;
  justify-content: center;

  @media (min-width: 756px) {
    flex: 66.6%;
  }

`;


const SLeftDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (min-width: 600px) {
    flex-direction: row;
    flex: 33%;
  }
`;

const SRigthDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 600px) {
    flex: 66.6%;
    padding-inline-end: 1vw;
  }

  @media (min-width: 756px) {
    flex-direction: row;
    flex: 66%;
    justify-content: start;
  }

  @media (min-width: 600px) and  (max-width: 680px){
    padding-inline-start: 2vw;
  }

  @media (min-width: 680px) and  (max-width: 756px){
    padding-inline-start: 8vw;
  }

 

  @media (min-width: 820px) {
    flex: 66%;
    padding-inline-end: 2vw;
  }

  @media (min-width: 960px) {
    flex: 66%;
    padding-inline-end: 4vw;
  }

  @media (min-width: 1024px) {
    flex:660%;
    padding-inline-end: 8vw;
  }

  @media (min-width: 1200px) {
    flex:660%;
    padding-inline-end: 8vw;
    margin-inline-start: 7vw;
  }
`;

const IconLinkWrapper = styled.a`
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;

const SHorizontalRule = styled.hr`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    color: gray;

    @media (min-width: 600px) {
    }
`;


const ICONStYTLE = {
   color: 'gray',
   marginInline: "8px"
};

const StyledLogoImg = styled.img`
    
    height: 50px;
    object-fit: cover;
    padding-inline: 24px;
    justify-self: center;
    margin: auto;

    @media (min-width: 600px) {
        margin: 0;
    }
`

const StyledLogoImgWrapper = styled(StyledLogoImg)`
    display: flex;
    margin-block-end: 4vh;

    @media (min-width: 600px) {
        padding-inline: 0;
        margin-block-end: 0vh;
        align-self: center;
        margin-inline-start: 0vh%;
    }
`

const GrayTwitterIcon = () => <TwitterIcon 
                                sx={ ICONStYTLE }
                              />

const GrayFacebookrIcon = () => <FacebookOutlinedIcon 
                                  sx={ ICONStYTLE }
                                 />                              

const GrayInstagramIcon = () => <InstagramIcon 
                                  sx={ ICONStYTLE }
                                />
                            

const AppFooter = () => {
  const navigate = useNavigate();
    const { t } = useTranslation();
    const state = React.useContext(globalStateContext)

    return <SFooterContainerDiv id="contact">
              <STopFooterContainer>
                <SLeftDivContainer>
                  <SColumn1Div>
                    <SContactContainerWrapper>
                      <StyledLogoImgWrapper src={logo}/>
                      <SContactContainer>
                        <SContactTitleContainer>{t("contactUs")}</SContactTitleContainer>
                        <SContactTextContainer>+57 3023463743</SContactTextContainer>
                        <SContactTextContainer>info@doctorhelp.com.co</SContactTextContainer>
                      </SContactContainer>
                    </SContactContainerWrapper>
                  </SColumn1Div>
                </SLeftDivContainer>
                <SRigthDivContainer>
                  <SColumn2Div>
                    <SColumnTitle>{t("products")}</SColumnTitle>
                    <StyledListContainer>
                      <SNormalText>{t("patientApps")}</SNormalText>
                      <SNormalText>{t("softwareForSpecialts")}</SNormalText>
                      <SNormalText>{t("telehealthCabins")}</SNormalText>
                    </StyledListContainer>
                  </SColumn2Div>
                  <SColumn3Div>
                    <SColumnTitle>{t("solutions")}</SColumnTitle>
                    <StyledListContainer>
                      <SNormalText href="/#solutions" onClick={ () => state.dispatch(0)}>{t("companies")}</SNormalText>
                      <SNormalText href="/#solutions" onClick={ () => state.dispatch(1)}>{t("clinics")}</SNormalText>
                      <SNormalText href="/#solutions" onClick={ () => state.dispatch(2)}>{t("insurance")}</SNormalText>
                      <SNormalText href="/#solutions" onClick={ () => state.dispatch(3)}>{t("governText")}</SNormalText>
                      <SNormalText href="/#solutions" onClick={ () => state.dispatch(4)}>{t("suppliers")}</SNormalText>
                    </StyledListContainer>
                  </SColumn3Div>
                </SRigthDivContainer>
              </STopFooterContainer>
              <SHorizontalRule/>
              <SBottomFooterContainer>
                  <SBottomFooterContent>
                      <SIconContainer>    
                        {
                         /* <IconLinkWrapper href='https://twitter.com/doctorhelpco' target="_black">
                                <GrayTwitterIcon/>
                             </IconLinkWrapper> */
                        }
                        <IconLinkWrapper href="https://www.facebook.com/eclinicdoctor" target="_black">
                          <GrayFacebookrIcon/>
                        </IconLinkWrapper>
                        <IconLinkWrapper href='https://www.instagram.com/eclinicdoctors/' target="_black">
                          <GrayInstagramIcon/>    
                        </IconLinkWrapper> 
                      </SIconContainer>
                  </SBottomFooterContent>
                  <SBottomFooterContent>
                        <SBottomFooterText>
                          {t("dehelpCopyright")}
                        </SBottomFooterText>
                  </SBottomFooterContent>
                  <SBottomFooterPolicyLinkContent>
                      <SBottomFooterText onClick={ () => navigate("privacy-policy")}>
                          {t("privacyPolicy")}
                      </SBottomFooterText>
                  </SBottomFooterPolicyLinkContent>
                </SBottomFooterContainer>
           </SFooterContainerDiv>;
};

export default AppFooter;
