import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SecondaryButton from '../second-button/SecondButton';
import ThirdSectionFeature from '../third-section-feature/ThirdSectionFeature';

interface Props {
    title: string;
    features: string[];
};


const StyledFeaturesDiv = styled.div`
    height: fit-content; 
    justify-content: start;
    display: flex;
    align-content: center;
    flex-direction: column;
    row-gap: 10px;
    column-gap: 10px;
    width: 100%;
` 
const SButtonContainer = styled.div`
    margin-block: 32px;
    width: fit-content;

    @media (min-width: 600px) {;
        margin-block-end: 0;
    }
`;

const StyledElementTitle = styled.h1`
    font-size: 1.2rem;
    font-family: "Montserrat";
    font-weight: 600;
    color: black;
    text-align: start;
`

const ThirdSectionTextElement = ({title, features}: Props) => 
           {
               const { t } = useTranslation();
               return <Fragment>
                <StyledElementTitle>{title}</StyledElementTitle>
                                    <StyledFeaturesDiv>
                                        {
                                           features.map(
                                               (feature, index) => 
                                               <ThirdSectionFeature
                                                    key={index} 
                                                    text={feature}/>
                                                    
                                           ) 
                                        }
                                    </StyledFeaturesDiv>
                                    <SButtonContainer>
                                        <SecondaryButton 
                                          text={t("knowMore")}
                                          urlToNavigate="https://api.whatsapp.com/send?phone=573023463743&text=Hola!%20Quiero%20solicitar%20un%20demo"/>
                                    </SButtonContainer>
                                    
            </Fragment>}

export default ThirdSectionTextElement;