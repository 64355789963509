import React from 'react';
import styled from 'styled-components';

interface CookieWrapprerProps {
    readonly title: React.ReactNode;
    readonly body: React.ReactNode
}

const SCookieContainer = styled.div`
    width: 80%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    border-radius: 5px;
    padding-block: 12px;
    margin: auto;

    @media (min-width: 600px) {
        width: 90%;
    }
`;

const SCookieTextContainer = styled.div`
    width: 100%;
    margin: auto;
    padding-block: 8px;
    color: black;
    font-size: 1.2rem;
    font-family: Karla;
    text-align: justify;

    @media (min-width: 600px) {
        width: 90%;
  }
`;

const STitleText = styled.h1`
    color: black;
    font-family: Montserrat;
    font-size: 1.5rem;
    margin-inline: .2rem;
    margin-block: 0;
    margin-bottom: 20px;
`;

const PolicyWrapper = ({title, body}: CookieWrapprerProps) => {
    return <SCookieContainer>
                 <STitleText>
                    {title}
                 </STitleText>
                <SCookieTextContainer>
                     {body}
                </SCookieTextContainer>
           </SCookieContainer>
};

export default PolicyWrapper;