import axios from 'axios';
import { Dispatch } from 'react';
import { PartialAppTextState } from '../data/app-texts';
import { Dictionary } from '../types/Dictionary';

export interface TraslaterServiceArg {
    textToTraslate: string,
    to?: string,
    from?: string
}

export const traslaterService = ( { textToTraslate, to = "en", from = "es" } :  TraslaterServiceArg) => axios.post(
    "https://translation.googleapis.com/language/translate/v2",
    {},
    {
      params: {
        q: textToTraslate,
        target: to,
        key: "AIzaSyBMzTy-z6izfdPktQrvXfFpnBOpecqJQkE",
      },
    }
  )

export const translationRequest = async (key: string, text: string, target: string = "en"): Promise<[string, string]> => {

    if( target == "en" ) {

      const storedText = getStoredText(key) as unknown as string
      console.log(`dot Request target: ${JSON.stringify(storedText)}  key: ${key}`);
      console.log(`Es falso: ${storedText}`)
      console.log(`Es Type: ${typeof storedText}`)
      if( Object.keys( storedText).length === 0 ) {
        console.log("dot Request for");
        const transalationRequest = await traslaterService({ textToTraslate: text, to: "en" })
        const translatedText = transalationRequest.data.data.translations[0].translatedText;
        storeTraslatedText(key, translatedText)
        return  [key, translatedText];
      } else {
        return [key, storedText]  
      }
    }
    return [key, text];    
  }
    
export function multipleTranslationsRequest<T extends PartialAppTextState>(
  textSet: Object,
   type: string, 
   dispatch: Dispatch<PartialAppTextState>,
   language: string = "en")  {
  let requests: Dictionary<Promise<[string, string]>> = {} as Dictionary<Promise<[string, string]>>
  const doResquest = async () => { 
      Object.entries(textSet).forEach(
          ([key, text]) =>  {
              requests[key] = translationRequest( key, text, language )
          }
      );
       let responses = await Promise.allSettled(Object.values(requests))  
       const translateEntry = {
          type
       } as { [key: string]: string} 
       responses.forEach( 
          ( res ) =>  {
              console.log("dot Request responses");
              if( res.status === "fulfilled" ) {
                  const resp = (res as PromiseFulfilledResult<[string, string]>).value;
                  console.log(`Respuesta es: ${resp}`);
                  const key = resp[0];
                  translateEntry[key] = resp[1];
              }
          }
       )
       const firstPartialSection = translateEntry as any as T
       console.log(`Dispatch ${JSON.stringify(firstPartialSection) }`);
       dispatch( firstPartialSection )      
  }
  doResquest()
} 


const STORAGE_KEY = 'react-translate-with-google-api';

const getStoredText = (key: string = STORAGE_KEY): { [key: string]: string } => {
    
    if (typeof window === 'undefined') {
      return {};
    }
    try {
      const item: string | null = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as { [key: string]: string }) : {};
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Get stored translation error', error);
      return {};
    }
  };

const LANGUAGE = 'language';


const storeTraslatedText = (key: string = STORAGE_KEY, value: string/*{ [key: string]: string }*/): string => {
   
    const emptyText = "";  
    if (typeof window === 'undefined') {
      return emptyText;
    }
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      return value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Store translation error', error);
    }
    return emptyText;
  };

  export const storeLanguage = (lang: string, key: string = LANGUAGE): string  => {
    return storeTraslatedText(key, lang)
  };

  interface TReturnUseTranslate {
    translatedData: {
      [key: string]: string;
    };
    loading: boolean;
  }


/*export const useTranslate = (
    language = 'en-US',
    initialData: {
      [key: string]: string;
    },
    options: {
      skip?: boolean;
      useStorage?: boolean;
    } = {}
  ): TReturnUseTranslate => {
    const { useStorage } = { useStorage: true, ...options };
    const { skip } = { skip: false, ...options };
  
    const [translatedData, setTranslatedData] = useState(initialData);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      if (skip) return;
      if (!translate) {
        throw new Error('Google Translate API is not setup yet');
      }
      void (async (): Promise<void> => {
        setLoading(true);
        for (const [key, value] of Object.entries(initialData)) {
          if (useStorage) {
            const storedValue = getStoredText(STORAGE_KEY);
            const existedValue = storedValue[`${language}-${key}`];
            if (existedValue) {
              setTranslatedData((prevState) => ({
                ...prevState,
                [key]: existedValue
              }));
              continue;
            }
          }
          try {
            const result = await translate.translate(value, language);
            const translatedText = result[0];
            setTranslatedData((prevState) => ({
              ...prevState,
              [key]: translatedText
            }));
            if (useStorage) {
              const storedValue = getStoredText(STORAGE_KEY);
              storeTraslatedText(
                {
                  ...storedValue,
                  [`${language}-${key}`]: translatedText
                },
                STORAGE_KEY
              );
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Error while translating: ${value} & language ${language}`, error);
          }
        }
        setLoading(false);
      })();
    }, [language]);
  
    return {
      translatedData,
      loading
    };
  };*/