import React, { useContext } from 'react';
import styled from 'styled-components';
import placeholderImg from "../../assets/imgs/eclinic_para_el_gobierno.jpg";
import ThirdSectionFeature from '../third-section-feature/ThirdSectionFeature';
import TabSectionImage from '../tab_section_img/tab_section.img';
import StyledTabTextWrapper from '../styled-tab-text-wrapper/styled-tab-text-wrapper';
import { GlobalTextStateContext } from '../../state/GlobalState';

/*const STabDiv = styled.div`
    width: 100%;
    background-color: #d9fbff;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
  }
`;

const SContent1Div = styled.div`
    width: 100%;
    background-color: #d9fbff;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
  }
`;

const SContent2Div = styled.div`
    width: 100%;
    background-color: #d9fbff;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
  }
`;

const SPresentationText = styled.p`
    color: #d9fbff;
`;

const SImg = styled.img`
  object-fit: cover;
  height: 200px;
  background-color: #f4f5f1;
`;

const SContentTitle = styled.h3`
  font-size: 1rem;
  text-align: center;
  color: gray; 
`;


const GovernTab = () => {
  const { t } = useTranslation();
  return <STabDiv>
          <SContent1Div>
            <SContentTitle>
              {t("Home")}
            </SContentTitle>
            <SPresentationText>
              {t("Home")}
            </SPresentationText>
          </SContent1Div>
          <SContent2Div>
            <SImg/>
          </SContent2Div>
         </STabDiv>;
};
*/

const SCompanyDiv= styled.div`
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 920px) {
        flex-direction: row;
  }
`;

const SCompanyTextDiv= styled.div`
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
`;

const SCompanySubtitleText= styled.p`
    text-align: start;
    font-family: "Karla";
    font-weight: 700;
    font-size: .9rem;
    color: black;
    padding-block: 16px;

    @media (min-width: 920px) {
      padding-block-start: 36px;
      padding-block-end: 24px;
      margin: 0;
   }
`;

const SCompanyText = styled.h3`
    width: 100%;
    text-align: start;
    font-family: "Karla";
    font-weight: 300;
    font-size: 1rem;
    color: black;

    @media (min-width: 920px) {
      min-width: 320px;
      max-width: 620px;
      text-align: justify;
      padding-block-start: 24px;
      padding-block-end: 0;
      margin: 0;
   }

`;




const GovernTab = () => {
                    const { fourSectionState } = useContext(GlobalTextStateContext)
                    const features = [ 
                                        fourSectionState.governTabBenfits1,
                                        fourSectionState.governTabBenfits2, 
                                        fourSectionState.governTabBenfits3, 
                                        fourSectionState.governTabBenfits4,
                                        fourSectionState.governTabBenfits5, 
                                        fourSectionState.governTabBenfits6, 
                                        fourSectionState.governTabBenfits7,
                   ];
                    return <SCompanyDiv>
                            <StyledTabTextWrapper>
                                <SCompanyTextDiv>
                                          <SCompanyText>{fourSectionState.governTabText}</SCompanyText>
                                          <SCompanySubtitleText>{fourSectionState.benefits}</SCompanySubtitleText>
                                          {
                                              features.map(
                                                  (feature, index) => 
                                                <ThirdSectionFeature
                                                            key={index} 
                                                      text={feature}/>
                                                              
                                                ) 
                                          }
                                  </SCompanyTextDiv>
                            </StyledTabTextWrapper>
                            <TabSectionImage imageUri={placeholderImg} />
                         </SCompanyDiv>;}

export default GovernTab;