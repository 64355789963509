export interface LanguajeSelectionState {
  selectedLanguage: string;
}


export interface IFirstSectionState {
  readonly firstSection: string;
  appBranch: string;
  softwareProfessionals: string;
  equippedCabins: string;
  creatService: string;
  telemedicine: string;
  teleMonitoring: string;
  preventiveCheckup: string;
  screening: string;
  automatedTriage: string;
  firstSectionSubtitle: string;
}

export interface ISecondSectionState {
  readonly secondSection: string;
  transformingHealth: string;
  interconnectedHealth: string;
  interconnectedHealthText: string;
  firstCabins: string;
  firstCabinsText: string;
}

export interface IThirdSectionState {
  readonly thirdSection: string;
  comprehensiveSolution: string;
  comprehensiveSolutionSubtitle: string;
  patientApps: string;
  adaptableIns: string;
  customizableBranch: string;
  accessibleAnwhere: string;
  softwareForProfessionals: string;
  readyForAttention: string;
  appointmentScheduling: string;
  electronicMedicalRecord: string;
  prescripcionesDigitales: string;
  telehealthCabins: string;
  connectDoctorPatients: string;
  teleconsultingRemoteMonitoring: string;
  preventiveMedicineCampaigns: string
}

export interface IFourSectionState {
  readonly fourSection: string;
  whoServe: string;
  whoServeSubtitle: string;
  companiesText: string;
  benefits: string;
  promotionWellBeing: string;
  absenteeismTracking: string;
  productivityIncrease: string;
  improvementQuality: string;
  increasedMotivation: string;
  strengthenEmployer: string;
  clinicBenefitsOne: string;
  clinicBenefitsTwo: string;
  clinicBenefitsThree: string;
  clinicBenefitsFour: string;
  clinicBenefitsFive: string;
  forClinics: string;
  benefitsForInsuresCompaniesone: string;
  benefitsForInsuresCompaniesTwo: string;
  benefitsForInsuresCompaniesThree: string;
  benefitsForInsuresCompaniesFour: string;
  benefitsForInsuresCompaniesFive: string;
  advantagesForInsOne: string;
  advantagesForInsTeo: string;
  advantagesForInsThree: string;
  advantagesForInsFour: string;
  insuresTabText: string;
  benefitsForInsuresCompaniesText: string;
  advantagesForIns: string;
  governTabBenfits1: string;
  governTabBenfits2: string;
  governTabBenfits3: string;
  governTabBenfits4: string;
  governTabBenfits5: string;
  governTabBenfits6: string;
  governTabBenfits7: string;
  governTabText: string;
  supplierBenefitsPatients1: string;
  supplierBenefitsPatients2: string;
  supplierBenefitsPatients3: string;
  supplierBenefitsPatients4: string;
  benefitsMedicalStaff1: string;
  benefitsMedicalStaff2: string;
  supplierTabText: string;
  supplierBenefitsPatientsTitle: string;
  benefitsMedicalStaffTitle: string;
}

export const FIRSTSECTIONSTATE: IFirstSectionState =  {
  firstSection: "secondSection",
  appBranch: "Apps con su marca para pacientes",
  softwareProfessionals: "Software para profesionales de la salud",
  equippedCabins: "Cabinas equipadas para la atención remota",
  creatService: "Cree su servicio de",
  telemedicine: "Telemedicina",
  teleMonitoring: "Telemonitoreo",
  preventiveCheckup: "Chequeo preventivo",
  screening: "Screening",
  automatedTriage: "Triage automatizado",
  firstSectionSubtitle: "eClinic ayuda a mejorar el acceso a la salud de calidad, mediante la innovación tecnológica",
}

export const SECONDSECTIONSTATE: ISecondSectionState = {
  secondSection: "secondSection",
  transformingHealth: "Transformando el cuidado de la salud",
  interconnectedHealth: "Un ecosistema de salud interconectado",
  interconnectedHealthText: "A través de una plataforma tecnológica web, aplicación móvil y cabinas de salud inteligentes, eClinic brinda servicios de atención medica en línea y monitoreo de salud de forma remota.",
  firstCabins: "Primeras cabinas de salud inteligentes",
  firstCabinsText: "Ubicadas en centros comerciales, empresas y clínicas, las cabinas de salud han permitido conectar médicos y pacientes en un espacio con equipamiento de última tecnología.",
}

export const THIRDSECTIONSTATE: IThirdSectionState = {
  thirdSection: "secondSection",
  comprehensiveSolution: "Una solución integral",
  comprehensiveSolutionSubtitle: "Nuestra plataforma incluye lo que necesita su institución para la atención en salud, 100% integrable con sus sistemas mediante APIs",
  patientApps: "Aplicaciones para pacientes",
  adaptableIns: "Adaptable a las necesidades de su institución.",
  customizableBranch: "Personalizable con su identidad de marca.",
  accessibleAnwhere: "Accesible desde la web y el móvil.",
  softwareForProfessionals: "Software para profesionales",
  readyForAttention: "Listo para la atenciön en salud",
  appointmentScheduling: "Agendamiento de citas.",
  electronicMedicalRecord: "Historia clínica electrónica",
  prescripcionesDigitales: "Prescripciones digitales.",
  telehealthCabins: "Estaciones de Telesalud",
  connectDoctorPatients: "Conectan médicos y pacientes a través de un espacio equipado, con dispositivos tecnológicos.",
  teleconsultingRemoteMonitoring: "Permiten la tele consulta y el monitoreo remoto.",
  preventiveMedicineCampaigns: "Brindan apoyo a campañas de medicina preventiva, chequeos automatizados y tamizajes de salud.",
}

export const FOURSECTIONSTATE: IFourSectionState = {
  fourSection: "fourSection",
  whoServe: "¿A quién atendemos?",
  whoServeSubtitle: "Nos adaptamos a las necesidades y casos de uso de cada empresa u organización, con productos y servicios que garanticen un mayor acceso al cuidado de la salud y al bienestar.",
  companiesText: "eClinic ofrece soluciones de telemedicina personalizadas para empresas, las cuáles permiten obtener excelentes resultados en el cuidado de la salud de los colaboradores, potenciando el bienestar y la prevención de enfermedades o accidentes en el entorno de trabajo.",
  benefits: "BENEFICIOS",
  promotionWellBeing: "Promoción del bienestar general dentro y fuera del entorno laboral",
  absenteeismTracking: "Seguimiento de ausentismos",
  productivityIncrease: "Incremento de la productividad",
  improvementQuality: "Mejora de la calidad de vida de los colaboradores",
  increasedMotivation: "Aumento de la motivación y el clima laboral",
  strengthenEmployer: "Potencia la marca empleadora",
  clinicBenefitsOne: "Conecta pacientes con profesionales de la salud de forma remota.",
  clinicBenefitsTwo: "Brinda acceso a una gran variedad de especialistas, de forma ágil y segura.",
  clinicBenefitsThree: "Agiliza el triage de pacientes con equipamiento de última tecnología",
  clinicBenefitsFour: "Disminuye demoras en la atención, aumenta el alcance de la atención médica, de los servicios de promoción y prevención de la salud.",
  clinicBenefitsFive: "Mejora la accesibilidad a los especialistas en zonas alejadas y con escasos recursos sanitarios, optimizando los recursos médicos disponibles.",
  forClinics: "eClinic brinda una solución completa, diseñada para Centros de Salud, Clínicas y Hospitales que necesitan extender los alcances de la atención sanitaria mediante una plataforma de telemedicina o agilizar los procesos de atención mediante el uso de tecnologías innovadoras.",
  benefitsForInsuresCompaniesone: "Ahorro secundario a la reducción de la siniestralidad.",
  benefitsForInsuresCompaniesTwo: "Aumento de la rentabilidad de las pólizas.",
  benefitsForInsuresCompaniesThree: "Diferenciación e innovación en la oferta.",
  benefitsForInsuresCompaniesFour: "Fortalecimiento de la marca aseguradora.",
  benefitsForInsuresCompaniesFive: "Atracción de nuevos asegurados.",
  advantagesForInsOne: "Disminución de los tiempos de espera por atención médica en clínicas y consultorios.",
  advantagesForInsTeo: "Reducción de costos e incomodidades por traslados a los centros de salud.",
  advantagesForInsThree: " Control y seguimiento remoto de la salud de cada asegurado.",
  advantagesForInsFour: "Diagnósticos fiables y tratamientos avalados por especialistas.",
  insuresTabText: "eClinic Mediante su ecosistema de salud conectado, aporta un nuevo valor a seguros de salud, aseguradoras generales, empresas de Servicios prehospitalarios y empresas de Medicina Prepagada. ",
  benefitsForInsuresCompaniesText: "BENEFICIOS PARA LA COMPAÑÍA DE SEGUROS:",
  advantagesForIns: "VENTAJAS PARA LOS ASEGURADOS:",
  governTabBenfits1: "Posibilita la prestación de servicios de salud en zonas remotas. ",
  governTabBenfits2: "Fortalece los servicios de atención primaria.",
  governTabBenfits3: "Aumenta la equidad en el acceso a los servicios de salud",
  governTabBenfits4: "Mejora de la eficiencia de los servicios sanitarios.",
  governTabBenfits5: "Optimización de recursos y reducción del gasto público",
  governTabBenfits6: "Mayor accesibilidad y cobertura en salud para la comunidad",
  governTabBenfits7: "Apoyo de la promoción y prevención de la salud.",
  governTabText: "eClinic Plantea soluciones a los retos y desafíos de la actualidad para los Centros de Atención primaria de la Salud, efectores de Salud de baja y mediana  complejidad, Organismos Públicos, Educación Pública y régimen especial de salud.",
  supplierBenefitsPatients1: "Cabinas con espacio privado, confortable y equipadas con equipos de última tecnología para la atención médica.",
  supplierBenefitsPatients2: "Atención inmediata sin esperas",
  supplierBenefitsPatients3: "Historia clínica digital accesible en la nube.",
  supplierBenefitsPatients4: "Diagnóstico y tratamiento más precisos.",
  benefitsMedicalStaff1: "Dispositivos biomédicos confiables, que apoyan y facilitan el proceso diagnóstico para el médico.",
  benefitsMedicalStaff2: "Mayor seguridad en la evaluación médica y el tratamiento del paciente.",
  supplierTabText:"eClinic Es un aliado tecnológico de los proveedores de servicios de salud digital y teleconsulta, que permite brindarle a los profesionales de salud y al paciente una experiencia de salud integral mediante el uso de cabinas de salud dotadas de equipos biomédicos.",
  supplierBenefitsPatientsTitle: "BENEFICIOS PARA LOS PACIENTES:",
  benefitsMedicalStaffTitle: "BENEFICIOS PARA EL PERSONAL MÉDICO:",
}

export type PartialAppTextState = LanguajeSelectionState | IFirstSectionState | ISecondSectionState | IThirdSectionState | IFourSectionState | IFourSectionState

export const data: { [key: string]: string} = {
    "patients": "Pacientes",
    "specialties": "Especialistas",
    "language": "Idioma",
    "spanish": "Español",
    "english": "Inglés",
    "requestDemo": "Solicitar demo",
    "products": "Productos",
    "solutions": "Soluciones",
    "contact": "Contacto",
    "companies": "Empresas",
    "clinics": "Clinicas",
    "insurances": "Seguros",
    "governText": "Gobierno",
    "suppliers": "Proveedores",
    "contactUs": "Contáctenos",    
    "seeMore": "Ver más",
    "knowMore": "Conocer más",
    "softwareForSpecialts": "Software para Especialistas",
    "dehelpCopyright":"Doctor Help SAS. Todos los derechos reservados. 2022",
    "privacyPolicy":"Política de privacidad",
    "cookiePolicy": "Usamos cookies para brindarle una mejor experiencia.",
    "cookiePolicyTextLink": "Política de cookies",
    "question": "¿Está listo para iniciar su servicio de Telemedicina?",
    "cookiePolicyText": "",
    "benefitsForInsuresCompaniesTwo": "Aumento de la rentabilidad de las pólizas.",
  }