import React, { useContext } from 'react';
import styled from 'styled-components';
import placeholderImg from "../../assets/imgs/eclinic_para_proveedores_de_telemedicina.jpg";
import ThirdSectionFeature from '../third-section-feature/ThirdSectionFeature';
import TabSectionImage from '../tab_section_img/tab_section.img';
import StyledTabTextWrapper from '../styled-tab-text-wrapper/styled-tab-text-wrapper';
import { GlobalTextStateContext } from '../../state/GlobalState';

const SCompanyDiv= styled.div`
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 920px) {
        flex-direction: row;
  }
`;

const SCompanyTextDiv= styled.div`
    width: 100%;
    flex: 66%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
`;

const SCompanySubtitleText= styled.p`
    text-align: start;
    font-family: "Karla";
    font-weight: 700;
    font-size: .9rem;
    color: black;
    padding-block: 16px;

    @media (min-width: 920px) {
        padding-block-start: 36px;
        padding-block-end: 24px;
        margin: 0;
     }
`;

const SCompanyText = styled.h3`
    width: 100%;
    text-align: start;
    font-family: "Karla";
    font-weight: 300;
    font-size: 1rem;
    color: black;

    @media (min-width: 920px) {
        min-width: 320px;
        max-width: 620px;
        text-align: justify;
        padding-block-start: 24px;
        padding-block-end: 0;
        margin: 0;
     }

`;


const ServiceSuppliersTab = () => {
                    const { fourSectionState } = useContext(GlobalTextStateContext)
                    const patientBenefits = [ fourSectionState.supplierBenefitsPatients1,
                                              fourSectionState.supplierBenefitsPatients2, 
                                              fourSectionState.supplierBenefitsPatients3, 
                                              fourSectionState.supplierBenefitsPatients4,
                                            ];
                    const medicalStaffBenefitsBenefits = [ 
                                                            fourSectionState.benefitsMedicalStaff1,
                                                            fourSectionState.benefitsMedicalStaff2, 
                                                        ];
                    return <SCompanyDiv>
                                <StyledTabTextWrapper>
                                    <SCompanyTextDiv>
                                    <SCompanyText>{ fourSectionState.supplierTabText }</SCompanyText>
                                    <SCompanySubtitleText>{ fourSectionState.supplierBenefitsPatientsTitle }</SCompanySubtitleText>
                                    {
                                        patientBenefits.map(
                                            (benefits, index) => 
                                        <ThirdSectionFeature
                                                    key={index} 
                                                text={benefits}/>
                                                        
                                        ) 
                                    }
                                    <SCompanySubtitleText>{ fourSectionState.benefitsMedicalStaffTitle }</SCompanySubtitleText>
                                    {
                                        medicalStaffBenefitsBenefits.map(
                                            (benefits, index) => 
                                        <ThirdSectionFeature
                                                    key={index} 
                                                    text={benefits}/>             
                                        ) 
                                    }
                                </SCompanyTextDiv>
                                </StyledTabTextWrapper>
                            <TabSectionImage imageUri={placeholderImg} />
                         </SCompanyDiv>;}

export default ServiceSuppliersTab;