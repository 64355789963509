import "./FloatingWhatsAppButton.css"
import React, { FC } from 'react';
import whatsaapIcon from "../../assets/imgs/whatsapp_icon.png";

const FloatingWhatsAppButton: FC<{whatsappNumber: string}> = ({whatsappNumber}: {whatsappNumber: string}) => {
        return <div className="whatsapp-image_container">
                    <a
                        href={`https://wa.me/${whatsappNumber}`}
                        target="_blank" 
                        rel="noreferrer">
                    <img 
                        className='landing-page-head-container_img' 
                        src={whatsaapIcon} alt="Logo de eclinic" />
                    </a>
               </div>
    };

export default FloatingWhatsAppButton;