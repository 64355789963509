import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ServiceItemNavigationProps {
  reference: string,
  children: ReactNode
}

const ServiceMenuTitle = styled.a`
  font-size: 1.0rem;
  text-align: center;
  color: gray;
  font-weight: bold;
  font-family: Montserrat;
  text-decoration: none;

  &:hover {
        cursor: pointer;
  }
  
  @media (min-width: 600px) {
    margin-inline-start: 5vw;
 }

  @media (min-width: 600px) and (max-width: 768px) {
    font-size: .9rem;
 }<

 @media (min-width: 768px) {
  font-size: 1.1rem;
}

`;

const ServiceItemNavigation: FC<ServiceItemNavigationProps> = ({reference, children}: ServiceItemNavigationProps) =>  <ServiceMenuTitle href={reference}>{children}</ServiceMenuTitle>

export default ServiceItemNavigation;