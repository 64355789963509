import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface StyledTabTextWrapperProps {
    children: ReactNode 
}

const STextDivContainer= styled.div`
    padding: 0;
    flex: 60%;
    order: 2;
    margin-inline-start: 16px;
    margin-inline-end: 24px;

    @media (min-width: 920px) {
        flex: 60%;
        order: 1;
    }

    @media (min-width: 1024px) {
        flex: 60%;
    }
`;

const StyledTabTextWrapper = ({children}: StyledTabTextWrapperProps) => <STextDivContainer>{children}</STextDivContainer>


export default StyledTabTextWrapper;
