import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledButton = styled.a`

    height: fit-content;
    width: fit-content;
    color: white;
    font-size: 1.3rem;
    font-weight: 700;
    background-color: #0ed2de;
    border-radius: 8px;
    border: none;
    text-decoration: none;
    margin-inline: auto;
    padding-block: 16px;
    padding-inline: 56px;
    margin-block: 24px;

    &:hover {
        cursor: pointer;
    } 

    @media (min-width: 756px) {
        margin-inline: 0;
        margin-block-start: 24px;
        margin-block-end: 8px;
    }
`

const MainButton = () => {
                        const { t } = useTranslation();
                        return <StyledButton href="https://api.whatsapp.com/send?phone=573023463743&text=Hola!%20Quiero%20solicitar%20un%20demo">
                                  {t("requestDemo")}
                               </StyledButton>}

export default MainButton

//<a href="https://wa.me/ + número prefijo país "sin signo +" + número de teléfono">Anchor text</a>
//<a href="https://wa.me/34555005500/?text=tu%20texto%20personalizado">Anchor text</a>
/*Hola!%20Quiero%20contratar%20sus%20servicios!
Lo que daría por resultado final del enlace de Whatsapp:

https://api.whatsapp.com/send?phone=34654087601&text= Hola!%20Quiero%20contratar%20sus%20servicios!*/