import React, { useEffect } from 'react';
import './App.css';
import MainPage from './pages/MainPage';
import { Suspense } from 'react';
import './i18n';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CookiePolicyPage from './pages/CookiePolicyPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/global';
import ThemeContext from './contexts/ThemeContext';
import { defaultTheme } from './styles/themes';
import GlobalStateProvider, { GlobalTextStateProvider } from './state/GlobalState';


function App() {

  const themeMode = defaultTheme;
  
  return (
    <div className="App">
      <body className="App-header">
        <ThemeContext>
          <ThemeProvider theme={themeMode}>
            <GlobalStateProvider>
              <GlobalTextStateProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={ <MainPage/>} /> 
                    <Route path="/cookie-policy" element={ <CookiePolicyPage/>} /> 
                    <Route path="/privacy-policy" element={ <PrivacyPolicyPage/>} /> 
                    <Route path="*" element={<div>Not found</div>} />
                  </Routes>
                </BrowserRouter>
              </GlobalTextStateProvider>
              <GlobalStyle/>
            </GlobalStateProvider>
          </ThemeProvider>
        </ThemeContext>
      </body>
    </div>
    
  );
}

const  WrappedApp = () =>
    <Suspense fallback="...is loading">
      <App />
    </Suspense>;


export default WrappedApp;
