import React from 'react';
import styled from 'styled-components';
import SecondaryButton from '../second-button/SecondButton';
import { useTranslation } from 'react-i18next';

type Props = {
    title: string;
    contentText: string;
    imgUrl: string;
    navigationLink: string;
    children: React.ReactNode;
    isPadding: boolean;
  };

  interface ImgContainerProps {
    readonly isPadding: boolean;
  }

const StyledDiv = styled.div`

    height: fit-content; 
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: white;
    margin-inline: 24px;
    margin-block: 12px;
    padding-block-end: 28px;
    border-radius: 16px;
    overflow: hidden;

    @media only screen and (max-width: 480px) {
      width: 320px;
    }

    @media  only screen and (min-width: 481px) and (max-width: 768px) {
      width: 340px;
    }

 
    @media  only screen and (min-width: 769px) and (max-width: 1024px)  {
      width: 340px;
    }

    @media  only screen and (min-width: 1025px) and (max-width: 1200px) {
      width: 340px;
    }


    @media (min-width: 1201px) {
      width: 340px;
   }
` 

const StyledTitle = styled.h3`

  font-size: 1rem;
  text-align: center;
  color: gray; 
  margin-inline: auto;
  padding-inline: 16px;
  margin-block: 28px;

`;

const StyledContentText = styled.p`

  font-size: 1rem;
  line-height: 1.2rem;  
  font-family: "Karla";
  text-align: center;
  color: gray; 
  padding-inline: 20px;
  padding-block: 0;
  margin-block: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 6rem;
  margin-block-end: 8px;
  
  @media (min-width: 600px) {
    /*height: 120px;*/  
  }

  @media  only screen and (min-width: 756px) and (max-width: 880px)  {
    /*height: 130px;*/
    height: 9.2rem;
  }

  @media only screen and (min-width: 1024px)  {
    height: 6rem;
  }
`;

const StyledImg = styled.img<ImgContainerProps>`
  object-fit: cover;
  height: auto;
  width: 100%;
  margin-top: ${(props) => (props.isPadding ? "32px" : "0")};
  background-color: #f4f5f1;
`;

const paddingConf = (withPadding: Boolean) => withPadding ? "32px" : "0";

const StyledImgContainner = styled.div<ImgContainerProps>`
    background-color: #111f72;
    display: flex;
    align-items: end;
    height: 140px;
    overflow: hidden;
    padding-inline: ${(props) => (paddingConf(props.isPadding))};
    padding-bottom: 0;
`;


const SecondSectionElement = ({title, imgUrl, contentText, navigationLink, isPadding, children}: Props) => 
   { 
    const { t } = useTranslation();
     return <StyledDiv>
                <StyledImgContainner isPadding={isPadding}>
                  <StyledImg 
                    isPadding={isPadding}
                    src={imgUrl} />
                </StyledImgContainner>
                <StyledTitle>{title}</StyledTitle>
                <StyledContentText>{contentText}</StyledContentText>
                <SecondaryButton text={t("seeMore")} urlToNavigate={navigationLink} />
            </StyledDiv>;
      }

export default SecondSectionElement;