import React, { useContext } from 'react';
import styled from 'styled-components';
import placeholderImg from "../../assets/imgs/eclinic_para_empresas.jpg";
import ThirdSectionFeature from '../third-section-feature/ThirdSectionFeature';
import TabSectionImage from '../tab_section_img/tab_section.img';
import StyledTabTextWrapper from '../styled-tab-text-wrapper/styled-tab-text-wrapper';
import { GlobalTextStateContext } from '../../state/GlobalState';

const SCompanyDiv= styled.div`
    width: 100%;
    background-color: #EFF6FF;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding: 0;

    @media (min-width: 920px) {
        flex-direction: row;
    }
`;

const SCompanyTextDiv= styled.div`
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
`;

const SCompanySubtitleText= styled.p`

    text-align: start;
    font-family: "Karla";
    font-weight: 700;
    font-size: .9rem;
    color: black;
    padding-block: 16px;

    @media (min-width: 920px) {

        padding-block-start: 36px;
        padding-block-end: 24px;
        margin-block: 0;
    } 
`;

const SCompanyText = styled.h3`
    width: 100%;
    text-align: start;
    font-family: "Karla";
    font-weight: 300;
    font-size: 1rem;
    color: black;
    order: 0;
    margin-block-start: 24px;

    @media (min-width: 920px) {
        order: 0;
        min-width: 320px;
        max-width: 620px;
        text-align: justify;
        margin-block-end: 0;
    } 
`;


const ForCompany = () => {
                    const { fourSectionState } = useContext(GlobalTextStateContext)
                    const features = [ fourSectionState.promotionWellBeing,
                                        fourSectionState.absenteeismTracking, 
                                        fourSectionState.productivityIncrease, 
                                        fourSectionState.improvementQuality,
                                        fourSectionState.increasedMotivation, 
                                        fourSectionState.strengthenEmployer, 
                ];
                    return <SCompanyDiv>
                            <StyledTabTextWrapper>
                                <SCompanyTextDiv>
                                    <SCompanyText>{fourSectionState.companiesText}</SCompanyText>
                                    <SCompanySubtitleText>{fourSectionState.benefits}</SCompanySubtitleText>
                                    {
                                        features.map(
                                            (feature, index) => 
                                        <ThirdSectionFeature
                                                    key={index} 
                                                text={feature}/>
                                                        
                                        ) 
                                    }
                                </SCompanyTextDiv>
                            </StyledTabTextWrapper>
                            <TabSectionImage imageUri={placeholderImg} />
                         </SCompanyDiv>;}

export default ForCompany;