import React from 'react';
import styled from 'styled-components';
import ServiceMenuTitle from '../service-menu-title/ServiceMenuTitle';
import { useTranslation } from 'react-i18next';

const StyledServiceHeader = styled.div`

    display: flex;
    height: fit-content; 
    width: 100%;
    justify-content: space-around;
    flex-direction: row;
    padding-inline: 24px;

    @media (min-width: 600px) {
        width: 50%;
        justify-content: end;
        padding-inline-end: 7vw;
    }
`

const ServiceHeader = () => {
                         const { t } = useTranslation();
                         return <StyledServiceHeader>
                                <ServiceMenuTitle reference={"/#products"}>{t("products")}</ServiceMenuTitle>
                                <ServiceMenuTitle reference={"/#solutions"}>{t("solutions")}</ServiceMenuTitle>
                                <ServiceMenuTitle reference={"/#contact"}>{t("contact")}</ServiceMenuTitle>
                            </StyledServiceHeader>}

export default ServiceHeader;