import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import MainSectionText from '../main-section-text/MainSectionText';
import StyledImgDiv from '../main-image-wrapper/MainImageWrapper';
import { GlobalTextStateContext } from '../../state/GlobalState';
import { multipleTranslationsRequest, translationRequest } from '../../services/traslater-service';
import { Dictionary } from '../../types/Dictionary';
import { IFirstSectionState } from '../../data/app-texts';

const StyledDiv = styled.div`

    height: fit-content; 
    background-color: white;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding-inline: 5%;
    padding-block: 48px;

    @media only screen and (max-width: 480px) {
       
    }

    @media (min-width: 756px) {
        padding-block: 36px;
   }
 
    @media (min-width: 820px) {
         flex-direction: row;
         padding-inline-start: 10%;
         padding-block: 36px;
    }
    
    @media  only screen and (min-width: 481px) and (max-width: 768px) {
        
    }

    
   
    @media  only screen and (min-width: 769px) and (max-width: 1024px)  {
         
    }
 
    @media  only screen and (min-width: 1024px) {
        padding-inline: 10%;
        padding-inline-end: 5%;
        margin: auto;
    }

    @media  only screen and (min-width: 1025px) and (max-width: 1200px) {
        padding-inline-end: 2%;
    }
 
 
     @media (min-width: 1201px) {
       
    }

   
`



const StyledLeftDiv = styled.div`
    height: auto; 
    justify-content: center;
    align-self: center;
    display: flex;
    flex: 50%;
    width: 100%;

    @media  only screen and (min-width: 756px) and (max-width: 992px)  {
        margin-inline-start: 24px;
    }

    @media  only screen and (max-width: 820px)  {
        margin-block-start: 24px;
    }
`

const FirstSection = () => { 
                            const { firstSectionState, dispatch, language } = useContext(GlobalTextStateContext)
                            useEffect(() => {
                                console.log("FirstSection");
                                multipleTranslationsRequest<IFirstSectionState>(firstSectionState, "firstSection", dispatch, language );
                            }, [language]);

                            
                        return <StyledDiv>
                                <MainSectionText/>
                                <StyledLeftDiv>
                                    <StyledImgDiv/>
                                </StyledLeftDiv>
                            </StyledDiv> 
                            }

export default FirstSection;                         