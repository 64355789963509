import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';



const SReadyContainerDiv = styled.div`
    width: 100%;
    background-color: #3244AD;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding-block: 48px;
`;


const SQuestionTitle = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  color: white; 
  font-weight: normal;
  margin-block: 0;
  margin-block-end: 16px;
  padding: 0;
  font-family: Karla;
  width: 66%;
  margin-inline: auto;

  @media (max-width: 756px) {
    width: 90%;
}


`;

const StyledButton = styled.button`
    background-color: white;
    height: 50px;
    color:  #3244AD;
    width: fit-content;
    padding-block: 8px;
    padding-inline: 48px;
    border-radius: 8px;
    margin: 0 auto;
    border: 2px solid #3244AD;
    cursor: pointer;
    margin-block-end: 0;
    margin-block-start: 16px;
    font-size: 1.25rem;
    font-family: Karla;
    font-weight: 900;
`;

const ArerREadyForTelemedicine = () => {
    const { t } = useTranslation();
    return <SReadyContainerDiv>
                <SQuestionTitle>
                    {t("question")}
                </SQuestionTitle>
                <StyledButton>
                {t("contactUs")}
                </StyledButton>
            </SReadyContainerDiv>
};

export default ArerREadyForTelemedicine;