import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

const StyledFeaturesItemDiv = styled.div`
    height: fit-content; 
    justify-content: start;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 10px;

    @media (min-width: 1024px) {
        max-width: 500px;   
  }
` 
const StyledFeaturesTextDiv = styled.p`
    font-size: 0.9rem;
    font-family: "Karla";
    color: black;
    margin-block: 0px;
    font-weight: 500;
    text-align: start;
    line-height: 1.6rem;
`

const SRoundedCheckIcon = styled.div`
    background-color: #0ed2de;
    border-radius: 50%;
    width: 20px;
    display: flex;
    align-items: center;
    height: 20px;
`

const ThirdSectionFeature = (props: {text: string}) => 
                                    <StyledFeaturesItemDiv>
                                        <SRoundedCheckIcon>
                                            <CheckIcon fontSize='small'/>
                                        </SRoundedCheckIcon>
                                        <StyledFeaturesTextDiv>{props.text}</StyledFeaturesTextDiv>
                                    </StyledFeaturesItemDiv>;

export default ThirdSectionFeature;