import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface StyleTabImgWrapperProps {
    children: ReactNode
}

const StyledImgWrapper = styled.div`
    flex: 66%;
    display: flex;
    align-self: start;
    padding-block: 24px;
    order: 1;
    margin: 0 auto;

    @media (min-width: 780px) {
        flex: 70%;
    } 

    @media (min-width: 920px) {
        order: 2;
        flex: 40%;
    } 
`;


const StyledTabImgWrapper = ({children}: StyleTabImgWrapperProps) => <StyledImgWrapper>
                                        {children}
                                   </StyledImgWrapper>;

export default StyledTabImgWrapper;                                   