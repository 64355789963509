import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';

const StyledDiv = styled.div`
    height: fit-content; 
    justify-content: start;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 10px;
    margin-inline-start: 5%;
    margin-block-end: 8px;

    @media (min-width: 756px) {
        margin-inline-start: 0%;
    }
`

const SRoundedCheckIcon = styled.div`
    background-color: #0ed2de;
    border-radius: 50%;
    width: 20px;
    display: flex;
    align-items: center;
    height: 20px;
`

const StyledText = styled.h5`
    color: black;
    font-size: 1.15rem;
    font-family: "Karla";
    text-align: start;
    font-weight: 400;
    margin: 0;

    @media  only screen and (min-width: 756px) and (max-width: 992px)  {
        font-size: 1.1rem;
    }
    
`

const ServiceItem = (prop: {text: String}) => <StyledDiv>
                                                 <SRoundedCheckIcon>
                                                     <CheckIcon  fontSize='small'/>
                                                 </SRoundedCheckIcon> 
                                                 <StyledText>{prop.text}</StyledText>
                                              </StyledDiv>

 export default ServiceItem;                                     