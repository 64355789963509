import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import NavBar from '../components/nav-bar/NavBar';
import SubHeaderWrapper from '../components/sub-header-wrapper/SubHeaderWrapper';
import AppFooter from '../components/dr_help_footer/dr_help_footer';

import i18n from "i18next";
import SpanishPolicy from '../components/cookie-policies/spanish-policy';
import EnglishPolicy from '../components/cookie-policies/english-policy';

const CookiePolicyContainer = styled.div`
    bacground-color: #FAFAFA;
    margin: auto;
`;

const CookiePolicyPage = () => {
                                const [lang, setLang] = useState(i18n.language);
                                i18n.on( 'languageChanged', (lng: string) => setLang(() => lng) );
                                useEffect(() => {}, [lang]);
                                return <Fragment>
                                        <NavBar/>
                                        <CookiePolicyContainer>
                                            <SubHeaderWrapper/>
                                                { ( lang == 'en')?  <EnglishPolicy/>: <SpanishPolicy/> }
                                        </CookiePolicyContainer>
                                        <AppFooter/>
                                        </Fragment>};

export default CookiePolicyPage;

