import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import NavBar from '../components/nav-bar/NavBar';
import SubHeaderWrapper from '../components/sub-header-wrapper/SubHeaderWrapper';
import AppFooter from '../components/dr_help_footer/dr_help_footer';

const PrivacyPolicyPage = () => <Fragment>
                                     <NavBar></NavBar>
                                     <SubHeaderWrapper></SubHeaderWrapper>
                                     <AppFooter></AppFooter>
                                </Fragment>;

export default PrivacyPolicyPage;