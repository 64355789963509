import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import SecondSectionElement from '../second-section-element/SecondSectionElement';
import theTimePublicaton from '../../assets/imgs/eclinic_header.jpg';
import cabinService from '../../assets/imgs/primeras_cabinas_de_salud.png';
import { useTranslation } from 'react-i18next';
import { GlobalTextStateContext } from '../../state/GlobalState';
import { multipleTranslationsRequest } from '../../services/traslater-service';
import { ISecondSectionState } from '../../data/app-texts';


const StyledSection= styled.section`

    background-color: #d9fbff;
    height: fit-content; 
    width: 100%;
    padding-block: 48px;
    justify-content: start;
    align-content: center;
    display: flex;
    flex-direction: column;

    @media (min-width: 756px) {
        width: 85%;
        padding-inline: 7.5%;
    }

`

const StyledSectionTitle = styled.h1`
    font-size: 1.5rem;
    font-family: "Montserrat";
    font-weight: 700;
    color: black;
    margin:auto;

    @media (max-width: 600px) {
        width: 80%;
        margin-block-end: 16px;
      }

    @media (min-width: 600px) {
        margin-block-start: 0;
        margin-block-end: 4px;
    }

    @media (min-width: 756px) {
        
    }
`

const StyledSectionContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-block-start: 16px;


    @media (min-width: 760px) {
        flex-direction: row;
        padding-block-start: 32px;
        padding-block-end: 8px;
    }
`

const SecondSection = () => {
                                const { secondSectionState, dispatch, language } = useContext(GlobalTextStateContext)
                                useEffect(() => {
                                    console.log("secondSection");
                                    multipleTranslationsRequest<ISecondSectionState>(secondSectionState, "secondSection", dispatch, language );
                                    }, [language]);

                        return  <StyledSection>
                                    <StyledSectionTitle>
                                        {secondSectionState.transformingHealth}
                                    </StyledSectionTitle>
                                    <StyledSectionContent>
                                        <SecondSectionElement 
                                            title={secondSectionState.interconnectedHealth} 
                                            contentText={secondSectionState.interconnectedHealthText}
                                            imgUrl={theTimePublicaton}               
                                            navigationLink="https://www.eltiempo.com/contenido-comercial/dr-help-el-ecosistema-de-salud-digital-para-clinicas-y-empresas-570517"
                                            isPadding={true}>
                                            <div></div>
                                        </SecondSectionElement>
                                        <SecondSectionElement 
                                            title={secondSectionState.firstCabins}  
                                            contentText={secondSectionState.firstCabinsText}  
                                            imgUrl={cabinService}
                                            navigationLink="https://vimeo.com/490136449"
                                            isPadding={false}>
                                            <div></div>
                                        </SecondSectionElement>
                                    </StyledSectionContent>
                                </StyledSection>}

export default SecondSection;
