import React from 'react';
import styled from 'styled-components';
import ServiceHeader from '../services-header/ServiceHeader';
import LogoImg from '../logo/DoctorhelpLogo';

const StyledSubHeaderWrapper = styled.div`
    height: fit-content; 
    width: 95%;
    justify-content: end;
    display: flex;
    flex-direction: column;
    padding-inline: 24px;
    padding-block: 16px;
    align-items: center;
    
    @media (min-width: 600px) {
         flex-direction: row;
         justify-content: space-between;
    }
`

const StyledImgContainer = styled.div`
    paddiing: 0;
    margin: 0;
    
    @media (min-width: 600px) {
     margin-inline-start: 5.75%;
   }
`

const SubHeaderWrapper = () => <StyledSubHeaderWrapper>
                                    <StyledImgContainer>
                                        <LogoImg/>
                                    </StyledImgContainer>
                                    <ServiceHeader/>
                               </StyledSubHeaderWrapper>

export default SubHeaderWrapper;