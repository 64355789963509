import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

interface CookiePolicyArgs {
    readonly clickHandler: () => void;
}

const SCookieContainerDiv = styled.div`
    width: 50%;
    background-color: #3244AD;
    justify-content: center;
    align-content: center;
    display: flex;
    margin: auto;
    flex-direction: row;
    height: fit-content;
    border-radius: 5px;
    padding-block: 8px;
    padding-inline: 8px;
    position: fixed;
    bottom: 0;

    @media only screen and (max-width: 759px) {
        width: 90%; 
    }

    @media only screen and (min-width: 920px)  and  and (max-width: 1056px){
        width: 75%; 
    }
`;

const SCookieTextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;
   height: fit-content;
   margin: 0;
   padding: 0;
   flex-grow: 9
`;

const SecondLineContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   justify-items: center;
`;


const SCloseIconDiv = styled(CloseIcon)`
    height: 20px;
    width: 50%;
    margin: 0;
    align-self: center;
    justify-self: end;
    cursor: pointer;
    flex-grow: 1
`;



const SPresentationText = styled.p`
    color: white;
    font-size: .9rem;
    margin-inline: .7ex;
    margin-block: 0;
    padding: 0;
`;

const SPresentationLinkText = styled.p`

    color: white;
    font-size: .9rem;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: white; 
    margin-block: 0;
    padding: 0;

    &:hover {
        cursor: pointer;
    } 
`;


const CookiePolicy = ({ clickHandler }: CookiePolicyArgs) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return <SCookieContainerDiv>
                <SCookieTextContainer>
                    <SPresentationText>
                        {t("cookiePolicy")}
                    </SPresentationText>
                    <SecondLineContainer>
                        <SPresentationText>
                            {t("readPolicy")}
                        </SPresentationText>
                        <SPresentationLinkText onClick={ () => navigate("cookie-policy") }>
                            {t("cookiePolicyTextLink")}
                        </SPresentationLinkText>
                    </SecondLineContainer>
                </SCookieTextContainer>
                <SCloseIconDiv onClick={() => clickHandler()}/>
           </SCookieContainerDiv>
};

export default CookiePolicy;

