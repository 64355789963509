import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import NavBar from '../components/nav-bar/NavBar';
import SubHeaderWrapper from '../components/sub-header-wrapper/SubHeaderWrapper';
import FirstSection from '../components/main-page-main-section/MainPageMainSection';
import SecondSection from '../components/main_second_section/MainSecondSection';
import ThirdSection from '../components/main-third-section/MainThirdSection';
import FourthSection from '../components/main-fourth-section/MainFourthSection';
import AppFooter from '../components/dr_help_footer/dr_help_footer';
import CookiePolicy from '../components/cookie_policy/cookie_policy';
import ArerREadyForTelemedicine from '../components/are_ready_for_telemedicine/are_ready_for_telemedicine';
import FloatingWhatsAppButton from '../components/floating-whatsapp-button/FloatingWhatsAppButton';
import {data as appText} from "../data/app-texts";
import { traslaterService } from '../services/traslater-service';


const StyledMainSectionDiv = styled.div`
      padding-inline: 0;
      width: 100%;
`;


const MainPage = () => {
                  const [isCookieHide, setIsCookieHide] = useState(true);
                  const [textTranlated, setTextTranlated] = useState("");

                      /*useEffect(() => {
                         // The text to translate
                         const text = 'Hola, son los perros del mundo!';
                         // The target language

                         const traslater = async () => {
                          const tranlated = await traslaterService({textToTraslate: text})
                          setTextTranlated(tranlated.data.keys);
                          console.log("RESPONSE: " + textTranlated)
                         }
                        traslater()
                         const target = 'es';
                         let translation: string[]
                          
                      }, []);*/



                  //console.log("algo: " + appText.firstSectionSubtitle);
                   return <Fragment>
                            <NavBar></NavBar>
                            <SubHeaderWrapper></SubHeaderWrapper>
                            <StyledMainSectionDiv>
                              <FirstSection/>
                              <SecondSection/>
                              <ThirdSection/>
                              <FourthSection/>
                              <ArerREadyForTelemedicine/>
                            </StyledMainSectionDiv>
                            <AppFooter/>
                            { isCookieHide && <CookiePolicy clickHandler={() => { setIsCookieHide(false) }}/>  } 
                            <FloatingWhatsAppButton  whatsappNumber= "573023463743"/> 

                      </Fragment>};

export default MainPage;