import React, { FC, PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import { useThemeMode } from '../../hooks/useThemeMode';
import { lightTheme, darkTheme, defaultTheme } from '../../styles/themes';


const ThemeContext: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { theme } = useThemeMode();

  const themeMode = theme === 'dark' ? /*darkTheme*/ defaultTheme : /*lightTheme*/ defaultTheme;

  return <ThemeProvider theme={themeMode}>{children}</ThemeProvider>;
};

export default ThemeContext;