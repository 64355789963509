import React from 'react';
import styled from 'styled-components';
import NavButton from '../nav-bar-button/NavBarButton';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GlobalTextStateContext } from '../../state/GlobalState';
import { useEffect } from 'react';
import { storeLanguage, translationRequest, traslaterService } from '../../services/traslater-service';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

/*previous background color #3244AD */;

const StyledNavBar = styled.div`

    background-color: #3244AD;      
    height: 7vh; 
    width: 100vw;
    justify-content: end;
    align-content: center;
    display: flex;
    flex-direction: row;
    padding-block: 4px;

    @media (min-width: 600px) {
        width: 90vw;
        padding-inline-end: 8.5vw;

    }


`

const NavBar = () => { 
                    const { t, i18n } = useTranslation();  
                    const { dispatch } = React.useContext(GlobalTextStateContext)
                    const [language, setLanguage] = React.useState("es");
                   // console.log("DATA: " + JSON.stringify(texts))
                    const changeLanguage = (lang: SelectChangeEvent<string>) => {
                        let selectedLanguage = lang.target.value;
                        console.log(`Change language ${selectedLanguage}`);
                        i18n.changeLanguage(selectedLanguage);
                        const languageUpdater = async () => {
                                const lang = storeLanguage(selectedLanguage);
                                dispatch( { selectedLanguage:lang } );  
                      };
                      setLanguage(selectedLanguage)
                      languageUpdater()
                     
                    }

                    return <StyledNavBar>
                        
                        <NavButton link='http://eclinic.com.co' >{t("institutions")}</NavButton>
                        <NavButton link='http://eclinic.com.co' >{t("patients")}</NavButton>
                        <NavButton link='https://especialistas.eclinic.com.co' >{t("specialties")}</NavButton>
                        <NavButton link='https://app.doctorhelp.com.co/Identity/Account/Login?isDoctor=true' >{t("iamDoctor")}</NavButton>
                        <div style={{ width: "1.0vw"}}></div>
                        { <FormControl sx={{ m: 1, 
                            minWidth: "100px",
                           
                            ' & .MuiFormControl-marginNormal': {border: '0px solid #000'} ,}}>
                            <InputLabel id="demo-simple-select-label"
                            sx={{
                                color: "white",
                                fontSize: 14,
                                fontFamily: "Karla"}}
                            >{t("language")}</InputLabel>
                            <Select
                            defaultValue="es"
                            sx={{
                                color: "white",
                                height: "30px",
                                fontSize: 16,
                                border: '0px solid #000',
                                fontFamily: "Karla",
                                '.MuiSelect-icon': { color: "white" },
                            }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={language}
                                label="Language"
                                onChange={changeLanguage}
                            >
                                <MenuItem value={"en"}>{t("english")}</MenuItem>
                                <MenuItem value={"es"}>{t("spanish")}</MenuItem>
                            </Select>
                        </FormControl> }
                     </StyledNavBar>}

export default NavBar;