import React from 'react';
import styled from 'styled-components';

// Previous background-color #3244AD

const NavButton = styled.a`
    background-color: #3244AD;
    font-size: 1rem;
    color: white;
    align-self: center;
    padding-inline: 16px;
    padding-block: 8px;
    border: none;
    text-decoration: none;
    &:hover {
        cursor: pointer;
    } 


`
const NavLinkedButton = (prop: {link: string, children: React.ReactNode}) => {
                             return <NavButton href={prop.link}>{prop.children}</NavButton>
                        }

export default NavLinkedButton;