import React from 'react';
import styled from 'styled-components';
import ThirdSectionTextElement from '../third-section-text-element/ThirdSectionTextElement';


interface Props {
            title: string;
            features: string[];
            imageUrl: string,
            isEven: boolean
};

interface orderProp {
  isAlignToLeft: string
}

const StyledDiv = styled.div`

    height: fit-content; 
    justify-content: start;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-block: 24px;
    margin: auto;
    align-content: center;
    justify-content: center;
    align-items: center;

    @media (min-width: 800px) {
        justify-content: center;
        width: 98%;
        margin-inline: 2%;
        padding-inline: 0px;
        flex-direction: row;
        column-gap: 4vw
  }
` 

const StyledImg = styled.img<orderProp>`
   object-fit: cover;
   min-width: 200px;
   order: 1;
   margin-inline: auto;
   max-width: 80%;
   height: auto;
   min-width: 200px;
   max-width: 440px;


@media (min-width: 600px) {
  order: ${props => props.isAlignToLeft };
  margin-inline: 0;
  width: 90%;

}
`

const StyledTextDiv = styled.div<orderProp>`

    order: 0;
    display: flex;
    margin-inline-start: 10%;
    align-content: center;
    flex-direction: column;
    margin-block: 0 auto;
    min-width: 300px;

    @media (min-width: 600px) {
        order: ${props => props.isAlignToLeft };
        margin-inline: 0; 
        max-width: 480px;
        width: 50%;
        align-content: start;
    }
` 

const ThirdSectionElement = ({title, features, imageUrl, isEven}: Props) =>
                                  <StyledDiv>
                                    <StyledImg src={imageUrl} isAlignToLeft={isEven? "0": "1"} ></StyledImg>
                                    <StyledTextDiv isAlignToLeft={isEven? "1": "0"} >
                                        <ThirdSectionTextElement 
                                            title={title} 
                                            features={features} />
                                    </StyledTextDiv>
                                  </StyledDiv>;

export default ThirdSectionElement;