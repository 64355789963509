import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import TabSection from '../main-fouth-tab-section/MainFourthTabSection';
import { multipleTranslationsRequest } from '../../services/traslater-service';
import { IFourSectionState } from '../../data/app-texts';
import { GlobalTextStateContext } from '../../state/GlobalState';

const StyledSection= styled.section`

    height: fit-content; 
    width: 85%;
    background-color: #EFF6FF;
    padding-block: 32px;
    justify-content: start;
    align-content: center;
    display: flex;
    flex-direction: column;
    padding-inline: 7.5%;

    @media (min-width: 756px) {
        padding-block: 48px;
    }
`;

const StyledSectionTitle = styled.h1`
    font-size: 1.5rem;
    font-family: "Montserrat";
    font-weight: 700;
    color: black;
    margin-block: 24px;

    @media (min-width: 600px) {
        margin-block-start: 0;
        margin-block-end: 24px;
    }
`;

const StyledSubTitle = styled.h2`
    font-size: 1rem;
    font-family: "Karla";
    font-weight: 300;
    color: black;
    margin-inline: auto;
    margin-block-start: 12px;
    margin-block-end: 48px;

  @media (min-width: 756px) {
        width: fit-content;
        font-size: 1.1rem;
        text-align: center;
        margin-inline: auto;
        max-width: 600px;
        max-width: 800px;
    }
`;

const FourthSection = () => {
                            const { fourSectionState, dispatch, language } = useContext(GlobalTextStateContext)
                            useEffect(() => {
                                console.log("FourSection");
                                multipleTranslationsRequest<IFourSectionState>(fourSectionState, "fourSection", dispatch, language );
                            }, [language]);

                            return <div id="solutions">
                                    <StyledSection>
                                        <StyledSectionTitle>{fourSectionState.whoServe}</StyledSectionTitle>
                                        <StyledSubTitle>{fourSectionState.whoServeSubtitle}</StyledSubTitle>
                                        <TabSection/>
                                   </StyledSection>
                            </div> };

export default FourthSection;