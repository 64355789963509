import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/imgs/eClinic_ints.png';


const StyledLogoImg = styled.img`
    
    object-fit: cover;
    /*padding-inline: 20px;*/
    justify-self: center;
    margin: auto;
    width: 320px;

    @media (min-width: 600px) {
        margin: 0;
    }
`

const StyledLogoImgWrapper = styled.div`
    display: flex;
    margin-block-end: 4vh;
    width: fit-content;

    @media (min-width: 600px) {
        padding-inline: 0;
        margin-block-end: 0vh;
        align-self: center;
        margin-inline-start: 6%;
    }
`

const LogoImg = () => <StyledLogoImgWrapper>
                        <StyledLogoImg src={logo} />
                     </StyledLogoImgWrapper>

export default LogoImg;