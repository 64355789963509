import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import ThirdSectionElement from '../third-section-element/ThridSectionElement';
import firstService from '../../assets/imgs/eclinic_celular.jpg'
import secondService from '../../assets/imgs/sotfware_servicio.svg'
import thirdService from '../../assets/imgs/cabina_pantallas_publicitarias.png'
import { useTranslation } from 'react-i18next';
import { GlobalTextStateContext } from '../../state/GlobalState';
import { IThirdSectionState } from '../../data/app-texts';
import { multipleTranslationsRequest } from '../../services/traslater-service';

const StyledSection= styled.section`
    height: fit-content; 
    width: 85%;
    padding-block: 32px;
    justify-content: start;
    align-content: center;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-inline: 7.5%;

    @media (min-width: 600px) {
        padding-block: 48px;
    }
`;

const StyledSectionTitle = styled.h1`
    font-size: 1.5rem;
    font-family: "Montserrat";
    font-weight: 700;
    color: black;
   /* color: #3244AD;*/
    margin-block: 24px;

    @media (min-width: 600px) {
        margin-block-start: 0;
        margin-block-end: 24px;
    }
`;

const StyledSubTitle = styled.h2`
    font-size: 1rem;
    font-family: "Karla";
    font-weight: 300;
    color: black;
    margin-inline: 5%;

    @media (min-width: 756px) {
        width: 80ex;
        margin-inline: auto;
        font-size: 1.1rem;
    }
`;

const StyledGridArea1 = styled.div`
    display: flex;
    justify-content: center;
    @media (min-width: 756px) {
       /* grid-area: row1;*/
    }
`;

const StyledGridArea2 = styled.div`
     display: flex; 
     justify-content: space-evenly;
     @media (min-width: 600px) and  (max-width: 800px){
        row-gap: 1vh;
    }
`;

const StyledGridArea3 = styled.div`
    display: flex;
    justify-content: space-evenly;
     @media (min-width: 756px) {
       /* grid-area: row3;*/
    }
`;

const StyledElementsWrapper= styled.div`
    
    height: fit-content; 
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    row-gap: 1vw;

   /* @media (min-width: 756px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        justify-items: center;
        grid-template-areas: 
            "row1 ." 
            "row1 ."
            "row1 row2"
            "row1 row2"
            ". row2"
            ". row2"
            ". row2"
            ". row2"
            "row3 row2"
            "row3 ."
            "row3 .";
  }*/

  @media only screen and (min-width: 756px) {
        max-width: 1000px;
  }

`;


const ThirdSection = () => {
                            const { thirdSectionState, dispatch, language } = useContext(GlobalTextStateContext);
                            useEffect(() => {
                                console.log("thirdSection");
                                multipleTranslationsRequest<IThirdSectionState>(thirdSectionState, "thirdSection", dispatch, language );
                                }, [language]);
                            return <StyledSection id="products">
                                        <StyledSectionTitle>{thirdSectionState.comprehensiveSolution}</StyledSectionTitle>
                                        <StyledSubTitle>{thirdSectionState.comprehensiveSolutionSubtitle}</StyledSubTitle>
                                        <StyledElementsWrapper>
                                            <StyledGridArea1>
                                                <ThirdSectionElement
                                                    title={thirdSectionState.patientApps}
                                                    features={ [ 
                                                        thirdSectionState.adaptableIns, 
                                                        thirdSectionState.customizableBranch, 
                                                        thirdSectionState.accessibleAnwhere] }
                                                    imageUrl={firstService}
                                                    isEven={true}/>
                                            </StyledGridArea1>
                                            <StyledGridArea2>
                                                <ThirdSectionElement
                                                    title={thirdSectionState.softwareForProfessionals}
                                                    features={[
                                                        thirdSectionState.readyForAttention, 
                                                        thirdSectionState.appointmentScheduling, 
                                                        thirdSectionState.electronicMedicalRecord, 
                                                        thirdSectionState.prescripcionesDigitales]}  
                                                    imageUrl={secondService}
                                                    isEven={false}
                                                ></ThirdSectionElement>
                                            </StyledGridArea2>   
                                            <StyledGridArea3>
                                                <ThirdSectionElement
                                                        title={thirdSectionState.telehealthCabins}
                                                        features={[
                                                            thirdSectionState.connectDoctorPatients,
                                                            thirdSectionState.teleconsultingRemoteMonitoring,
                                                            thirdSectionState.preventiveMedicineCampaigns]}
                                                        imageUrl={thirdService}
                                                        isEven={true}
                                                    ></ThirdSectionElement>
                                            </StyledGridArea3>  
                                        
                                        </StyledElementsWrapper>   
                            </StyledSection>}; 

export default ThirdSection;