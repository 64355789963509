import { Box, Tab, Tabs } from '@mui/material';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import ForCompany from '../for-company/ForCompany';
import { useTranslation } from 'react-i18next';
import ClinicTab from '../clinics_tab/clinics_tab';
import TabPanel from '../tab-panel-content/tab-panel-content';
import InsureTab from '../insures_tab/insures_tab';
import GovernTab from '../govern_tab/govern_tab';
import ServiceSuppliersTab from '../services_suppliers/services-suppliers';
import GlobalStateProvider, { globalStateContext } from '../../state/GlobalState';


const DrHelpTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  textTransform: 'none',
  
  '&.Mui-selected': {
    backgroundColor: '#000',
  },

  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    textTransform: 'none',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
    textTransform: 'none',
  },
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))( ({ theme }) => ({

  color: 'rgba(255, 255, 255, 0.7)',
  textTransform: 'none',

  '&.css-1ujykiq-MuiButtonBase-root-MuiTab-root': {
    textTransform: 'none',
  },
  
  '&.MuiTab-root.Mui-selected': {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  
  '&.Mui-selected': {
    borderRadius: 10, 
    color: '#000',
    fontWeight: '800',
    backgroundColor: '#0ed2de',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#3244AD',
  },
}));

const TabSection = () => {
    
    const state = React.useContext(globalStateContext)
    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
      state.dispatch(newValue);
    };

    const { t } = useTranslation();  

    return <>
                <Box sx={{ borderBottom: 1, 
                      borderColor: 'divider',
                      backgroundColor: 'white',
                      marginInline: "24px", 
                      borderRadius: "8px",
                     }}>
                <DrHelpTabs 
                  variant="fullWidth"
                  scrollButtons="auto"
                  value={state.mainTabSelection} 
                  onChange={handleChange} aria-label="services for">
                    <StyledTab label={t("companies")}  />
                    <StyledTab label={t("clinics")}  />
                    <StyledTab label={t("insurance")}  />
                    <StyledTab label={t("governText")}  />
                    <StyledTab label={t("suppliers")}  />
                </DrHelpTabs>
                </Box>
                <TabPanel 
                  value={state.mainTabSelection} 
                  index={0}>
                  <ForCompany/>
                </TabPanel>
                <TabPanel 
                  value={state.mainTabSelection} 
                  index={1}>
                  <ClinicTab/>
                </TabPanel>
                <TabPanel 
                  value={state.mainTabSelection} 
                  index={2}>
                  <InsureTab/>
                </TabPanel>
                <TabPanel 
                  value={state.mainTabSelection} 
                  index={3}>
                  <GovernTab/>
                </TabPanel>
                <TabPanel 
                  value={state.mainTabSelection}
                  index={4}>
                  <ServiceSuppliersTab/>
                </TabPanel>
            </>
} 

export default TabSection;
