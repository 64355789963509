import React, { FC } from 'react';
import styled from 'styled-components';
import StyledTabImgWrapper from '../styled-tab-img-wrapper/styled-tab-img-wrapper';


const StyledImg = styled.img`
    width: 100%;
    min-width: 150px;
    max-width: 520px;
    height: 250px;
    object-fit: cover;
    margin: auto;
    border-radius: 25px;
`;

interface StyledImageWrapperArgs {
    readonly imageUri: string;
}

const TabSectionImage: FC<StyledImageWrapperArgs> = ({ imageUri }) =>{
    return <> 
       <StyledTabImgWrapper >
            <StyledImg src={ imageUri }/>
       </StyledTabImgWrapper>
    </>
} 


export default TabSectionImage;
